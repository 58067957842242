class Animation{
  constructor (array){
    let commonArray = array
    if(commonArray['path'] === undefined){ commonArray.path = '/assets/img/animation/'}
    if(commonArray['time'] === undefined){ commonArray.time = 4000}
    if(commonArray['extension'] === undefined){ commonArray.extension = 'png'}

    this.array = commonArray

  }
  setImg(){
    let array = this.array;
    const element = document.querySelector(array.ele)
    for (  let i = 0;  i < array.itemLen;  i++  ) {
      let span = document.createElement('span')
      span.classList.add('mvFrame')
      if(i === 0){ span.classList.add('js-active') }
      let img = document.createElement('img')
      img.src = array.path + array.fileName + '_' + this.ret(i) + '.' + array.extension
      span.appendChild(img)
      element.appendChild(span)
    }
  }
  ret(num){
    let ret = ( '00' + num ).slice( -2 )
    return ret
  }

  start(){
    let array = this.array;
    let element = document.querySelector(array.ele).querySelectorAll('.mvFrame');
    const totalTime = array.time
    const itemLength = array.itemLen - 1;
    let time = totalTime / itemLength
    let count = 0;
    let countUp = function(){
      if(count > itemLength){
        count = 0
      }
      element[count].classList.remove('js-active')
      if(count === itemLength){
        element[0].classList.add('js-active')
      }else{
        element[count + 1].classList.add('js-active')
      }
      count ++;
    }
    setInterval(countUp, time);
  }
}
